var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "", loading: _vm.loading } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "heading" }, [_vm._v("Accountability Setup")])
      ]),
      _c(
        "v-card-text",
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              "Setting up " +
                _vm._s(_vm.appName) +
                " Accountability is easy and only takes a few minutes."
            )
          ]),
          _c("div", { staticClass: "text-h5" }, [_vm._v("Recommended Steps")]),
          _c("ol", { staticStyle: { "padding-left": "40px" } }, [
            _c(
              "li",
              { staticStyle: { margin: "5px" } },
              [
                _c("completed-icon", {
                  staticStyle: { "margin-left": "-45px" },
                  attrs: { completed: _vm.setupProgress.onboardingSurvey }
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold",
                    staticStyle: { "margin-left": "18px" }
                  },
                  [_vm._v("Take the onboarding survey.")]
                ),
                _c("span", { staticClass: "pl-1 font-italic" }, [
                  _vm._v("(~1 minute)")
                ]),
                !_vm.setupProgress.onboardingSurvey
                  ? _c(
                      "span",
                      { staticClass: "font-italic error--text pl-2" },
                      [_vm._v("(incomplete)")]
                    )
                  : _vm._e(),
                _c("br"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", small: "" },
                    on: {
                      click: function($event) {
                        _vm.showOnboardingSurvey = true
                      }
                    }
                  },
                  [_vm._v("Take survey")]
                )
              ],
              1
            ),
            _c(
              "li",
              { staticStyle: { margin: "5px" } },
              [
                _c("completed-icon", {
                  staticStyle: { "margin-left": "-45px" },
                  attrs: { completed: _vm.setupProgress.addDevices }
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold",
                    staticStyle: { "margin-left": "18px" }
                  },
                  [_vm._v("Add devices to your account")]
                ),
                _c("span", { staticClass: "pl-1 font-italic" }, [
                  _vm._v("(~3 minutes / device)")
                ]),
                !_vm.setupProgress.addDevices
                  ? _c(
                      "span",
                      { staticClass: "font-italic error--text pl-2" },
                      [_vm._v("(incomplete)")]
                    )
                  : _vm._e(),
                _c("br"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", small: "" },
                    on: {
                      click: function($event) {
                        _vm.showAddDeviceDialog = true
                      }
                    }
                  },
                  [_vm._v("Download app")]
                ),
                _c("div", { staticClass: "caption font-italic mt-1" }, [
                  _vm._v(
                    "\n            Repeat for each device that needs to be monitored.\n          "
                  )
                ]),
                _c("add-device-dialog", {
                  model: {
                    value: _vm.showAddDeviceDialog,
                    callback: function($$v) {
                      _vm.showAddDeviceDialog = $$v
                    },
                    expression: "showAddDeviceDialog"
                  }
                })
              ],
              1
            ),
            _c(
              "li",
              { staticStyle: { margin: "5px" } },
              [
                _c("completed-icon", {
                  staticStyle: { "margin-left": "-45px" },
                  attrs: {
                    completed: _vm.setupProgress.addAccountabilityPartner
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold",
                    staticStyle: { "margin-left": "18px" }
                  },
                  [_vm._v("Setup daily/weekly reports and alerts")]
                ),
                _c("span", { staticClass: "pl-1 font-italic" }, [
                  _vm._v("(1 minute)")
                ]),
                !_vm.setupProgress.addAccountabilityPartner
                  ? _c(
                      "span",
                      { staticClass: "font-italic error--text pl-2" },
                      [_vm._v("(incomplete)")]
                    )
                  : _vm._e(),
                _c("br"),
                !_vm.seeHow.devices
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", small: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/emails")
                          }
                        }
                      },
                      [_vm._v("\n            Go to reports page\n          ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "li",
              { staticStyle: { margin: "5px" } },
              [
                _c("completed-icon", {
                  staticStyle: { "margin-left": "-45px" },
                  attrs: { completed: _vm.setupProgress.e2ee }
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold",
                    staticStyle: { "margin-left": "18px" }
                  },
                  [_vm._v("Enable end-to-end encryption")]
                ),
                _c("span", { staticClass: "pl-1 font-italic" }, [
                  _vm._v("(2 minutes)")
                ]),
                !_vm.setupProgress.e2ee
                  ? _c(
                      "span",
                      { staticClass: "font-italic error--text pl-2" },
                      [_vm._v("(incomplete)")]
                    )
                  : _vm._e(),
                _c("div", [
                  _vm._v(
                    "Enabling end-to-end encryption will increase the security of your accountability data.  Failure to enable will result in a $5 / month non-compliance fee."
                  )
                ]),
                !_vm.seeHow.devices
                  ? _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", small: "" },
                        on: { click: _vm.goToEnableE2EE }
                      },
                      [_vm._v("\n            Enable E2EE\n          ")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
          _c("div", { staticClass: "font-italic" }, [
            _vm._v("If you have any problems, please email "),
            _c("a", { attrs: { href: "mailto:support@truple.io" } }, [
              _vm._v("support@truple.io")
            ]),
            _vm._v(".")
          ]),
          _c("br"),
          _c(
            "div",
            [
              _c("span", { staticClass: "text-h5 pr-2" }, [
                _vm._v("Optional Steps")
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "ma-0 pa-0 text--theme--primary font-italic",
                  staticStyle: { cursor: "pointer" },
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.optionalShown = !_vm.optionalShown
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.optionalShown ? "hide" : "show"))]
              )
            ],
            1
          ),
          _c("v-expand-transition", [
            _vm.optionalShown
              ? _c("ul", { staticStyle: { "padding-left": "40px" } }, [
                  _c(
                    "li",
                    { staticStyle: { margin: "5px" } },
                    [
                      _c("completed-icon", {
                        staticStyle: { "margin-left": "-45px" },
                        attrs: {
                          completed: false,
                          optional: true,
                          indeterminate: true
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          staticStyle: { "margin-left": "18px" }
                        },
                        [_vm._v("Adjust Device Settings")]
                      ),
                      _c("span", { staticClass: "font-italic pl-1" }, [
                        _vm._v("(optional, 2 minutes)")
                      ]),
                      _c("div", [
                        _vm._v(
                          "When devices are added to your account, they use default settings that work well under most circumstances.  However you can further customize those settings."
                        )
                      ]),
                      !_vm.seeHow.devices
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/devices")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Go to device settings\n            "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticStyle: { margin: "5px" } },
                    [
                      _c("completed-icon", {
                        staticStyle: { "margin-left": "-45px" },
                        attrs: { completed: false, optional: true }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "font-weight-bold",
                          staticStyle: { "margin-left": "18px" }
                        },
                        [_vm._v("Lock account")]
                      ),
                      _c("span", { staticClass: "font-italic pl-1" }, [
                        _vm._v("(optional, 1 minute)")
                      ]),
                      _c("div", [
                        _vm._v(
                          "If you wish, you can lock your account.  Locking will prevent changes from being made on your account.  In order to unlock, an unlock passcode will be sent to a report recipient that you must then enter in."
                        )
                      ]),
                      !_vm.seeHow.devices
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/account")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              Go to accounts page\n            "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      ),
      _c("survey-onboarding", {
        model: {
          value: _vm.showOnboardingSurvey,
          callback: function($$v) {
            _vm.showOnboardingSurvey = $$v
          },
          expression: "showOnboardingSurvey"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }