<template>
  <v-container style="max-width: 800px;">
    <setup-screenshot-accountability class="mb-2" />

    <setup-filtering />

    <v-dialog
      max-width="400px"
      v-model="showJustSubscribedDialog"
    >
      <v-card>
        <v-card-title class="d-block">
          Congratulations!
          <v-btn icon class="float-right" color="primary" @click="showJustSubscribedDialog = false"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div>You now have access to the best online protection available.</div>
          <br />
          <div>This is our setup guide.  It outlines everything you need to do to get the most out of {{ appName }}.</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block outlined @click="showJustSubscribedDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


<!--    (optional)-->
<!--    1.  Adjust device settings to be exactly how you want-->
<!--    2.  Review the filtering guide at-->
<!--    3.  Lock your account (if you're holding yourself accountable)-->

  </v-container>
</template>

<script>
  import SetupScreenshotAccountability from "./SetupScreenshotAccountability";
  import SetupFiltering from "./SetupFiltering";

  export default {
    name: "SetupGuide",
    components: {SetupScreenshotAccountability, SetupFiltering},
    data() {
      return {
        appName: process.env.VUE_APP_NAME,
        showJustSubscribedDialog: false
      }
    },
    beforeMount() {
      this.showJustSubscribedDialog = this.$route.query.justSubscribed
    }
  }
</script>

<style scoped>

</style>