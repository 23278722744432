var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    {
      staticClass: "pr-2",
      attrs: {
        dense: "",
        color: _vm.colorOverride
          ? _vm.colorOverride
          : _vm.completed
          ? "#00796B"
          : _vm.optional
          ? "#FFC107"
          : "#b71c1c"
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(
            _vm.completed
              ? "check_box"
              : _vm.indeterminate
              ? "indeterminate_check_box"
              : "check_box_outline_blank"
          ) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }