var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "max-width": "800px" } },
    [
      _c("setup-screenshot-accountability", { staticClass: "mb-2" }),
      _c("setup-filtering"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.showJustSubscribedDialog,
            callback: function($$v) {
              _vm.showJustSubscribedDialog = $$v
            },
            expression: "showJustSubscribedDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "d-block" },
                [
                  _vm._v("\n          Congratulations!\n          "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { icon: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.showJustSubscribedDialog = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _c("div", [
                  _vm._v(
                    "You now have access to the best online protection available."
                  )
                ]),
                _c("br"),
                _c("div", [
                  _vm._v(
                    "This is our setup guide.  It outlines everything you need to do to get the most out of " +
                      _vm._s(_vm.appName) +
                      "."
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", block: "", outlined: "" },
                      on: {
                        click: function($event) {
                          _vm.showJustSubscribedDialog = false
                        }
                      }
                    },
                    [_vm._v("\n            Close\n          ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }