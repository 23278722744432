<template>
  <div class="mt-6">
    <div class="title">{{ question }}</div>
    <div class="caption font-italic">
      {{ multipleAnswers ? 'Multiple answers allowed' : 'One answer allowed' }}
    </div>
    <v-row dense>
      <v-col :cols="12" :md="6"
             v-for="answerText in potentialAnswers"
             :key="answerText">
        <v-btn
            block
            color="primary"
            :outlined="!answers[answerText]"
            @click="clicked(answerText)">
          <div class="d-inline-block" style="width: 100%;">
            <v-icon small class="float-left" color="accent">
              {{answers[answerText] ? 'check_box' : 'check_box_outline_blank'}}
            </v-icon>
            <span>{{ answerText }}</span>
          </div>
        </v-btn>
      </v-col>
      <v-col :cols="12" :md="6" v-if="other">
        <v-btn
            block
            color="primary"
            outlined
            @click="toggleOther">
          <div class="d-inline-block" style="width: 100%;">
            <v-icon small class="float-left" color="accent">
              {{showOtherTextField ? 'check_box' : 'check_box_outline_blank'}}
            </v-icon>
            <span>Other</span>
          </div>
        </v-btn>
      </v-col>
      <v-col :cols="12" v-if="showOtherTextField">
        <v-text-field autofocus label="Other text" hint="Please fill in your answer above" :persistent-hint="true" outlined v-model="answers['other_freetext']" />
      </v-col>
      <v-col :cols="12" v-if="!req">
        <v-btn text block >Skip</v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "MultipleChoice",
  props: {
    value: {},
    question: {
      type: String,
      required: true
    },
    req: {
      type: Boolean,
      default: true
    },
    potentialAnswers: {
      type: Array,
      required: true
    },
    multipleAnswers: {
      type: Boolean,
      default: false
    },
    other: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOtherTextField: false,
      answers: this.value
    }
  },
  methods: {
    toggleOther() {
      this.showOtherTextField = !this.showOtherTextField
      if(!this.showOtherTextField) {
        this.answers.other_freetext = null
      }
    },
    clicked(val) {
      console.log(`updating ${val} ${!this.answers[val]}`)
      if(this.multipleAnswers) {
        if(this.answers[val]) {
          delete this.answers[val]
        } else {
          this.answers[val] = true
        }
        this.answers = {...this.answers}
      } else {
        this.answers = {
          [val]: !this.answers[val]
        }
      }
      this.$emit('input', this.answers)
    }
  }
}
</script>

<style scoped>

</style>