var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6" },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.question))]),
      _c("div", { staticClass: "caption font-italic" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.multipleAnswers
                ? "Multiple answers allowed"
                : "One answer allowed"
            ) +
            "\n  "
        )
      ]),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _vm._l(_vm.potentialAnswers, function(answerText) {
            return _c(
              "v-col",
              { key: answerText, attrs: { cols: 12, md: 6 } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      block: "",
                      color: "primary",
                      outlined: !_vm.answers[answerText]
                    },
                    on: {
                      click: function($event) {
                        return _vm.clicked(answerText)
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-inline-block",
                        staticStyle: { width: "100%" }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "float-left",
                            attrs: { small: "", color: "accent" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.answers[answerText]
                                    ? "check_box"
                                    : "check_box_outline_blank"
                                ) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("span", [_vm._v(_vm._s(answerText))])
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          }),
          _vm.other
            ? _c(
                "v-col",
                { attrs: { cols: 12, md: 6 } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "", color: "primary", outlined: "" },
                      on: { click: _vm.toggleOther }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-inline-block",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "float-left",
                              attrs: { small: "", color: "accent" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.showOtherTextField
                                      ? "check_box"
                                      : "check_box_outline_blank"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c("span", [_vm._v("Other")])
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showOtherTextField
            ? _c(
                "v-col",
                { attrs: { cols: 12 } },
                [
                  _c("v-text-field", {
                    attrs: {
                      autofocus: "",
                      label: "Other text",
                      hint: "Please fill in your answer above",
                      "persistent-hint": true,
                      outlined: ""
                    },
                    model: {
                      value: _vm.answers["other_freetext"],
                      callback: function($$v) {
                        _vm.$set(_vm.answers, "other_freetext", $$v)
                      },
                      expression: "answers['other_freetext']"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          !_vm.req
            ? _c(
                "v-col",
                { attrs: { cols: 12 } },
                [
                  _c("v-btn", { attrs: { text: "", block: "" } }, [
                    _vm._v("Skip")
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }