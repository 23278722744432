<template>
  <v-card outlined>
    <v-card-title>
      <div class="heading">Filter Setup</div>
    </v-card-title>
    <v-card-subtitle>
      Optional
    </v-card-subtitle>
    <v-card-text>
      To setup filtering, see the <a @click="openFilteringPage">filtering resource page</a>.

<!--      To use the premium features of our android web filter app, click the Screenshot Accountability Login button, then login with your Screenshot Accountability credentials. <br />-->
<!--      <v-btn text @click="showGif('Android Web Filter', '/static/img/setup/webfilter.gif')">View Instructions</v-btn>-->
<!--      <br /><br />-->
<!--      The <a @click="openFilteringPage">filtering resource page</a> provides recommendations for free filters across most platforms.-->
    </v-card-text>

    <gif-dialog v-if="gif.show" :title="gif.title" :src="gif.src" :show="gif.show" @close="closeGifDialog" />
  </v-card>
</template>

<script>
  import AndroidInterface from "../../services/AndroidInterface";
  import GifDialog from "./GifDialog";

  export default {
    name: "SetupFiltering",
    components: {
      GifDialog
    },
    data() {
      return {
        gif: {
          title: '',
          src: '',
          show: false
        }
      }
    },
    methods: {
      openFilteringPage() {
        if(this.$store.state.inApp) {
          AndroidInterface.openWebsiteInBrowser(`${process.env.VUE_APP_HOST}/filter`)
        } else {
          this.$router.push('filter')
        }
      },
      showGif(title, src) {
        this.gif.title = title
        this.gif.src = src
        this.gif.show = true
      },
      closeGifDialog() {
        this.gif.show = false
      }
    }
  }
</script>

<style scoped>

</style>
