var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { outlined: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "heading" }, [_vm._v("Filter Setup")])
      ]),
      _c("v-card-subtitle", [_vm._v("\n      Optional\n    ")]),
      _c("v-card-text", [
        _vm._v("\n      To setup filtering, see the "),
        _c("a", { on: { click: _vm.openFilteringPage } }, [
          _vm._v("filtering resource page")
        ]),
        _vm._v(".\n\n")
      ]),
      _vm.gif.show
        ? _c("gif-dialog", {
            attrs: {
              title: _vm.gif.title,
              src: _vm.gif.src,
              show: _vm.gif.show
            },
            on: { close: _vm.closeGifDialog }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }