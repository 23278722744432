<template>
  <v-dialog v-model="showDialog" max-width="600px" scrollable persistent :fullscreen="$vuetify.breakpoint.smAndDown">
    <v-card>
      <v-card-title class="primary">
        <span class="white--text">Onboarding Survey</span>
        <v-spacer />
        <v-btn icon @click="closeOnboardingSurvey"><v-icon color="white">close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <survey-question
            :back-button-text="null"
            :next-disabled="!answers.devices || Object.keys(answers.devices).length === 0"
            v-if="currentQuestion === 0"
            @on-next="currentQuestion = 1"
        >
          <multiple-choice
              question="Which device types do you need to monitor?"
              v-model="answers.devices"
              :potential-answers="[
                'Android',
                'Windows',
                'Mac',
                'iPhone / iPad',
                'Kindle Fire',
                'Chromebook',
                'Linux'
            ]"
              :multiple-answers="true"
          />
        </survey-question>
        <survey-question
            v-if="currentQuestion === 1"
            :next-disabled="!answers.source"
            @on-back="currentQuestion = 0"
            @on-next="currentQuestion = 2"
        >
          <multiple-choice
              :question="`How did you learn about ${appName}?`"
              v-model="answers.source"
              :potential-answers="learnAboutAnswers"
              :other="true"
              :randomize="true"
          />
        </survey-question>
        <survey-question
            v-if="currentQuestion === 2"
            next-button-text="Submit"
            :next-disabled="!answers.organization"
            :next-loading="submitting"
            @on-back="currentQuestion = 1"
            @on-next="submit"
        >
          <yes-no :question="`Are you a part of an organization or group that would be interested in partnering with ${appName} to provide group discounts?`"
                  v-model="answers.organization" />
        </survey-question>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SurveyApi from "@/aws/survey/SurveyApi";
import SurveyQuestion from "@/components/surveys/SurveyQuestion";
const learnAboutOrderedAnswers = [
  'Friend / Family',
  'Google/Search Engine',
  'Google Play Store',
  'Facebook',
  // 'Google Search Ad',
  'Instagram',
  'Reddit',
  'Church',
  // 'Facebook Group',
  // 'Guard Your Eyes',
  // 'Fort Safety'
]

import YesNo from "@/components/surveys/questions/YesNo";
import MultipleChoice from "@/components/surveys/questions/MultipleChoice";
import Utils from "@/Utils";
import StoreConstants from "@/StoreConstants";
export default {
  name: "SurveyOnboarding",
  components: {SurveyQuestion, MultipleChoice, YesNo},
  props: {
    value: {}
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      currentQuestion: 0,
      submitting: false,
      showDialog: this.value,
      answers: {
        devices: {},
        source: {},
        organization: null,
        selfAccountability: null
      },
      learnAboutAnswers: Utils.shuffle(learnAboutOrderedAnswers)
    }
  },
  methods: {
    async submit() {
      try {
        this.submitting = true

        const surveyCategory = 'onboarding'
        await SurveyApi.submitSurveyResults(surveyCategory, this.answers)
        await Utils.sleep(3000)
        await this.$store.dispatch(StoreConstants.actions.loadAccount)
        this.closeOnboardingSurvey()
      } catch(e) {
        // eslint-disable-next-line no-console
        console.log(e)
        this.$swal('an error occurred')
        throw e
      } finally {
        this.submitting = false
      }
    },
    closeOnboardingSurvey() {
      this.showDialog = false
      this.$emit('input', false)
      this.onComplete()
    },
    onComplete() {
      this.$emit('onComplete')
    }
  },
  computed: {
    canSubmit() {
      return this.answers.organization !== null &&
          // this.answers.selfAccountability !== null &&
          Object.values(this.answers.devices).filter(x => x).length > 0 &&
          Object.values(this.answers.source).filter(x => x).length > 0
    }
  },
  watch: {
    value: function(newVal) {
      this.showDialog = newVal
    }
  }
}
</script>

<style scoped>

</style>
