<template>
  <v-icon dense :color="colorOverride ? colorOverride : (completed ? '#00796B' : ( optional ? '#FFC107' : '#b71c1c'))" class="pr-2">
    {{completed ? 'check_box' : (indeterminate ? 'indeterminate_check_box' : 'check_box_outline_blank')}}
  </v-icon>
</template>

<script>
  export default {
    name: "CompletedIcon",
    props: {
      completed: {
        type: Boolean,
        default: false
      },
      optional: {
        type: Boolean,
        default: false
      },
      indeterminate: {
        type: Boolean,
        default: false
      },
      colorOverride: {
        type: String,
        default: null
      }
    }
  }
</script>

<style scoped>

</style>